import React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';

export const CustomNavbar = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <nav className="bg-white text-white ">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-2">
        <div className="flex items-center justify-between h-20">
          {/* Logo */}
          <div className="flex items-center">
            <img
              src={require('../../assets/images/logo-dark.png')}
              alt="Logo"
              className="w-[250px] h-auto"
            />
          </div>

          {/* Desktop Menu */}
          {/* <div className="hidden md:flex md:items-center md:space-x-12">
            <a href="/" className="relative text-black hover:text-gray-500 py-2 transition-all before:content-[''] before:absolute before:bottom-0 before:left-1/2 before:w-0 before:h-[2px] before:bg-white before:transition-all before:duration-300 hover:before:left-0 hover:before:w-full">
              Products
            </a>
            <a href="/#about" className="relative text-black hover:text-gray-500 py-2 transition-all before:content-[''] before:absolute before:bottom-0 before:left-1/2 before:w-0 before:h-[2px] before:bg-white before:transition-all before:duration-300 hover:before:left-0 hover:before:w-full">
              Pricing
            </a>
            <a href="/#contact" className="relative text-black hover:text-gray-500 py-2 transition-all before:content-[''] before:absolute before:bottom-0 before:left-1/2 before:w-0 before:h-[2px] before:bg-white before:transition-all before:duration-300 hover:before:left-0 hover:before:w-full">
              Resources
            </a>
            <a href="/gift-vouchers"  className="relative text-black hover:text-gray-500 py-2 transition-all before:content-[''] before:absolute before:bottom-0 before:left-1/2 before:w-0 before:h-[2px] before:bg-white before:transition-all before:duration-300 hover:before:left-0 hover:before:w-full">
              Job seekers
            </a>
            <a href="/cart" className="text-white py-3 px-8 rounded-full bg-teal-500 hover:bg-teal-400 ">
              <span className="flex items-center space-x-2">
                Book Demo
              </span>
            </a>
          </div> */}

          {/* coming soon nav */}
          <div className="hidden md:flex md:items-center md:space-x-12">
            {/* <a href="/about" className="relative text-black hover:text-gray-500 py-2 transition-all before:content-[''] before:absolute before:bottom-0 before:left-1/2 before:w-0 before:h-[2px] before:bg-white before:transition-all before:duration-300 hover:before:left-0 hover:before:w-full">
              About
            </a>
            <a href="/pricing" className="relative text-black hover:text-gray-500 py-2 transition-all before:content-[''] before:absolute before:bottom-0 before:left-1/2 before:w-0 before:h-[2px] before:bg-white before:transition-all before:duration-300 hover:before:left-0 hover:before:w-full">
              Pricing
            </a>
            <a href="/resources" className="relative text-black hover:text-gray-500 py-2 transition-all before:content-[''] before:absolute before:bottom-0 before:left-1/2 before:w-0 before:h-[2px] before:bg-white before:transition-all before:duration-300 hover:before:left-0 hover:before:w-full">
              Resources
            </a>
            <a href="/jobs"  className="relative text-black hover:text-gray-500 py-2 transition-all before:content-[''] before:absolute before:bottom-0 before:left-1/2 before:w-0 before:h-[2px] before:bg-white before:transition-all before:duration-300 hover:before:left-0 hover:before:w-full">
              Job seekers
            </a> */}
            <a href="https://link.youcrew.com.au/download" target='_blank' rel="noreferrer" className="text-white py-3 px-8 rounded-full bg-teal-500 hover:bg-teal-400 ">
              <span className="flex items-center space-x-2">
                Download App
              </span>
            </a>
          </div>

          {/* Mobile Menu Button */}
          <div className="md:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="text-black focus:outline-none"
            >
              <svg
                className="h-6 w-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                {isOpen ? (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                ) : (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                )}
              </svg>
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      <div
        className={`fixed top-0 left-0 w-full h-full bg-white bg-opacity-90 z-50 transition-transform duration-300 ease-in-out transform ${
          isOpen ? 'translate-x-0' : '-translate-x-full'
        }`}
      >
        {/* Close Button */}
        <div className="flex justify-end px-4 pt-4">
          <button
            onClick={() => setIsOpen(false)}
            className="text-black focus:outline-none"
          >
            <svg
              className="h-6 w-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>

        {/* Mobile Menu Links */}
        <div className="w-full bg-white h-full px-4 pt-2 pb-3 space-y-4 absolute left-0">
          {/* <Link
            to="/"
            onClick={() => setIsOpen(false)}
            className="block text-black hover:text-gray-300 px-3 py-2 rounded-md"
          >
            Home
          </Link>
          <a
            href="#about"
            onClick={() => setIsOpen(false)}
            className="block text-black hover:text-gray-300 px-3 py-2 rounded-md"
          >
            About Us
          </a>
          <a
            href="#contact"
            onClick={() => setIsOpen(false)}
            className="block text-black hover:text-gray-300 px-3 py-2 rounded-md"
          >
            Contact
          </a> */}
          {/* <Link
            to="/gift-vouchers"
            onClick={() => setIsOpen(false)}
            className="block text-black hover:text-gray-300 px-3 py-2 rounded-md"
          >
            Gift Vouchers
          </Link> */}
          <Link
            to="https://link.youcrew.com.au/download"
            onClick={() => setIsOpen(false)}
            className="text-white text-center hover:text-gray-800 py-3 w-full px-12 rounded-full  bg-teal-500 hover:bg-teal-400  block"
          >
            Download App
          </Link>
        </div>
      </div>
    </nav>
  );
};
