import React, { useState } from "react";
import { CustomNavbar, Footer } from "../../components";
import { FaSpinner} from 'react-icons/fa';

export const HomeScreen = () => {

    const [email, setEmail] = useState<string>('');
    const [isSubmitting] = useState(false);
    const [formStatus] = useState<any>(null);
      
    return(
        <>
            <div className="font-inter antialiased bg-light bg-white text-black tracking-tight">
                <div className="flex flex-col min-h-screen overflow-hidden">
                    <CustomNavbar />
                    <section className="flex flex-col lg:flex-row items-center justify-between py-16 bg-gray-100 relative h-screen">
                        {/* Text Section */}
                        <div className="flex flex-col items-start lg:w-1/2 px-6 lg:px-12 mb-8 lg:mb-0">
                            <h1 className="text-[55px]  md:text-7xl md:text-[90px] lg:text-10xl font-black mb-4 text-black">Hire Smarter, Not Harder</h1>
                            <p className="text-gray-500 font-light mb-6 md:mb-6 md:text-2xl">YouCrew helps you to make informed hiring decisions and build stronger teams. Let us help you eliminate the guesswork and choose candidates who can truly get the job done.</p>
                            {/* <a href="#f" className="text-white py-3 px-[4em] rounded-full bg-teal-500 hover:bg-teal-400 font-bold">
                                Get Started
                            </a> */}
                            {isSubmitting ? (
                                <div className="flex items-center justify-center">
                                    <FaSpinner className="animate-spin text-teal-500 text-5xl" />
                                </div>
                            ) : formStatus === 'success' ? (
                                <div className="text-green-500 text-lg font-semibold text-center md:text-left">
                                    You have successfully joined the waiting list!
                                </div>
                            ) : (
                                <form
                                    name="waitinglist"
                                    method="POST"
                                    id="waitListForm"
                                    className="w-full sm:w-full rounded-md focus:outline-none focus:border-teal-500 text-lg"
                                    data-netlify="true" data-netlify-honeypot="bot-field"
                                >
                                    {/* Netlify Hidden Fields */}
                                    <input type="hidden" name="form-name" value="waitinglist" />
                                
                                    <label htmlFor="email" className="sr-only">
                                        Email Address
                                    </label>
                                    <div className="w-full sm:flex flex-col sm:flex-row items-center">
                                        <input
                                            type="email"
                                            id="email"
                                            name="email"
                                            placeholder="example@example.com"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            required
                                            className="flex-1 py-3 w-full px-4 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-teal-500 text-lg mb-4 sm:mb-0 "
                                        />
                                        <button
                                            type="submit"
                                            className="py-3 px-8 bg-teal-500 text-white border border-bg-teal-400 rounded-md font-semibold text-lg hover:bg-teal-400 transition duration-200"
                                        >
                                            Join Waiting List
                                        </button>
                                    </div>
                                </form>
                            )}
                        </div>

                        {/* Image Section */}
                        <div className="lg:w-2/1 flex justify-center lg:justify-end px-6 lg:px-12">
                            <img 
                                className="w-full h-auto max-w-md lg:max-w-full object-cover"
                                src={require('../../assets/images/hero-image.png')} 
                                alt="hero" 
                            />
                        </div>
                    </section>

                    <section id="step1" className="bg-white py-[15%]">
                        <div id="step1" className="mx-3 px-3 sm:px-5">
                            <h4 className="text-2xl md:text-4xl font-bold mb-10 text-left">1. Set up your assessments</h4>

                            {/* First Row */}
                            <div className="flex flex-col md:flex-row items-center mb-16 space-y-8 md:space-y-0 md:space-x-8">
                                <div className="md:w-1/2 lg:w-3/5">
                                    <p className="text-lg md:text-2xl text-gray-700">
                                        Select from a range of assessments tailored for blue-collar workers across all categories, from skilled trades to general labor. 
                                    </p>
                                </div>
                                <div className="md:w-1/2 lg:w-2/5">
                                    <img src={require('../../assets/images/job-role-image.png')} alt="job roles selection" className="w-full h-auto object-cover" />
                                </div>
                            </div>

                            {/* Second Row */}
                            <div className="flex flex-col md:flex-row items-center mb-16 space-y-8 md:space-y-0 md:space-x-8">
                                <div className="md:w-1/2 lg:w-2/4">
                                    <img src={require('../../assets/images/choose-assessments-image.png')} alt="choose assessment view" className="w-full h-auto object-cover" />
                                </div>
                                <div className="md:w-1/2 lg:w-3/5">
                                    <p className="text-lg md:text-2xl text-gray-700">
                                        Our assessments focus on practical skills and real-world tasks, ensuring they’re accessible for non-technical candidates and relevant to your needs.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id="step2" className=" bg-white py-[15%]">
                        <div id="step2" className="mx-3 px-3 sm:px-5">
                            <div className="flex flex-col md:flex-row items-center mb-16 space-y-8 md:space-y-0 md:space-x-8">
                                <div className="md:w-1/2 lg:w-2/4">
                                    <img src={require('../../assets/images/step2-image.png')} alt="step 2 view" className="w-full h-auto object-cover" />
                                </div>
                                <div className="md:w-1/2 lg:w-3/5">

                                    <h4 className="text-2xl md:text-4xl font-bold mb-10 text-left">2. Invite your candidates</h4>
                                    <p className="text-lg md:text-2xl text-gray-700">
                                        Send invitations to job applicants to complete the assessments. You can copy the link and share it with candidates or send it directly to their email from the platform. 
                                    </p>
                                    <p className="text-lg md:text-2xl text-gray-700 mt-4">
                                        Monitor their progress and review results to identify the top performers. With YouCrew, you get clear insights to help you filter out unsuitable candidates and hire the best for the job.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id="step3" className="py-16 bg-white">
                        <div id="step3" className="mx-0 px-0 sm:px-0">
                            <div className="flex flex-col md:flex-row items-center mb-16 space-y-8 md:space-y-0 md:space-x-8">
                                <div className="md:w-1/2 lg:w-3/5 px-6 sm:px-20">
                                    <h4 className="text-2xl md:text-4xl  font-bold mb-10 text-left">3. Focus on Quality Candidates</h4>
                                    <p className="text-lg md:text-2xl text-gray-700">
                                        YouCrew helps you filter out the noise and focus on top-quality candidates, ensuring they know what they’re doing before they get to the job site. 
                                    </p>
                                    <p className="text-lg md:text-2xl text-gray-700 mt-4">
                                        Limit your worries about hiring the wrong people and avoid wasting thousands trying to find the right fit. Let YouCrew streamline the process so you can hire with confidence.
                                    </p>
                                    {/* <div className="my-5 justify-center items-center flex">
                                        <a href="#f" className="text-white text-lg md:text-2xl py-3 px-[4em] rounded-full bg-teal-500 hover:bg-teal-400 font-bold">
                                            Get Started
                                        </a>
                                    </div> */}
                                </div>
                                <div className="md:w-1/2 lg:w-2/4">
                                    <img src={require('../../assets/images/step3-image.png')} alt="worker view" className="w-full h-auto object-cover" />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="py-16 bg-white">
                        <div className="mx-10 px-0 sm:px-0">
                            <h4 className="text-2xl md:text-4xl  font-bold mb-10 text-center md:text-left">And thats not all...</h4>
                            <div className="flex flex-col md:flex-row items-center justify-center mb-16 space-y-8 md:space-y-0 md:space-x-8">
                                <div className="md:w-1/2 lg:w-1/4">
                                    <img src={require('../../assets/images/create-your-own-image.png')} alt="create your own" className="w-full h-auto object-cover" />
                                </div>
                                <div className="md:w-1/2 lg:w-1/4">
                                    <img src={require('../../assets/images/easy-to-use-image.png')} alt="easy to use" className="w-full h-auto object-cover" />
                                </div>
                                <div className="md:w-1/2 lg:w-1/4">
                                    <img src={require('../../assets/images/find-talent-image.png')} alt="find talent" className="w-full h-auto object-cover" />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id="waitList" className="py-16 bg-white">
                        <div className="mx-0 px-0 sm:px-0">
                            <div className="flex flex-col md:flex-row items-center mb-16 space-y-8 md:space-y-0 md:space-x-8">
                                <div className="md:w-1/2 lg:w-2/4">
                                    <img
                                    src={require('../../assets/images/waitlist-image.png')}
                                    alt="worker view"
                                    className="w-full h-auto object-cover"
                                    />
                                </div>
                                <div className="md:w-1/2 lg:w-3/5 px-6 sm:px-20">
                                    <h4 className="text-2xl md:text-4xl font-bold mb-10 text-center md:text-left">
                                        Join the waiting list
                                    </h4>
                                    <p className="text-lg md:text-2xl text-gray-700 mb-6 text-center md:text-left">
                                        Subscribe now to secure your place in line and get notified when we launch.
                                    </p>

                                    {isSubmitting ? (
                                        <div className="flex items-center justify-center">
                                            <FaSpinner className="animate-spin text-teal-500 text-5xl" />
                                        </div>
                                    ) : formStatus === 'success' ? (
                                        <div className="text-green-600 text-lg font-semibold text-center md:text-left">
                                            You have successfully joined the waiting list!
                                        </div>
                                    ) : (
                                        <form
                                            name="waitinglist"
                                            method="POST"
                                            id="waitListForm"
                                            className="w-full sm:w-full rounded-md focus:outline-none focus:border-teal-500 text-lg"
                                            data-netlify="true" data-netlify-honeypot="bot-field"
                                        >
                                            {/* Netlify Hidden Fields */}
                                            <input type="hidden" name="form-name" value="waitinglist" />
                                        
                                            <label htmlFor="email" className="sr-only">
                                                Email Address
                                            </label>
                                            <div className="w-full sm:flex flex-col sm:flex-row items-center">
                                                <input
                                                    type="email"
                                                    id="email"
                                                    name="email"
                                                    placeholder="example@example.com"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    required
                                                    className="flex-1 py-3 px-4 w-full rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-teal-500 text-lg mb-4 sm:mb-0 "
                                                />
                                                <button
                                                    type="submit"
                                                    className="py-3 px-8 bg-teal-500 text-white border border-bg-teal-400 rounded-md font-semibold text-lg hover:bg-teal-400 transition duration-200"
                                                >
                                                    Join Waiting List
                                                </button>
                                            </div>
                                        </form>
                                    )}

                                    {/* Error Message (if any) */}
                                    {formStatus === 'error' && (
                                        <div className="text-red-400 text-sm mt-4 text-center md:text-left">
                                            There was an error submitting the form. Please try again.
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </section>

                    <Footer />

                </div>

            </div> 
        </>
    )

}